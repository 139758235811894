import _ from 'xe-utils';
import CryptoJS from 'crypto-js';
import request from './request';
import Storage from './storage';

export const showTitle = (item) => {
  let title = '';
  title = (item.meta && item.meta.title) || item.name;
  return title;
};

/**
 * @param {*} list 现有标签导航列表
 * @param {*} newRoute 新添加的路由原信息对象
 * @description 如果该newRoute已经存在则不再添加
 */
export const getNewTagList = (list, newRoute) => {
  const { name, path, meta } = newRoute;
  const newList = [...list];
  if (newList.findIndex((item) => item.name === name) >= 0) return newList;
  newList.push({ name, path, meta });
  return newList;
};

/**
 * @param {*} obj1 对象
 * @param {*} obj2 对象
 * @description 判断两个对象是否相等，这两个对象的值只能是数字或字符串
 */
export const objEqual = (obj1, obj2) => {
  const keysArr1 = Object.keys(obj1);
  const keysArr2 = Object.keys(obj2);
  if (keysArr1.length !== keysArr2.length) return false;
  if (keysArr1.length === 0 && keysArr2.length === 0) return true;
  /* eslint-disable-next-line */
  return !keysArr1.some((key) => obj1[key] != obj2[key]);
};

/**
 * @description 根据name/params/query判断两个路由对象是否相等
 * @param {*} route1 路由对象
 * @param {*} route2 路由对象
 */
export const routeEqual = (route1, route2) => {
  const params1 = route1.params || {};
  const params2 = route2.params || {};
  const query1 = route1.query || {};
  const query2 = route2.query || {};
  return route1.name === route2.name && objEqual(params1, params2) && objEqual(query1, query2);
};

export const getRouteTitleHandled = (route) => {
  const router = { ...route };
  const meta = { ...route.meta };
  let title = '';
  // console.log(window.App.$store.state.menus.menusName);
  if (meta.title) {
    // if (typeof meta.title === 'function') {
    //   meta.__titleIsFunction__ = true;
    //   title = meta.title(router);
    // } else
    title = meta.title;
  }
  meta.title = title;
  router.meta = meta;
  return router;
};

function transMenu(menu, openNames) {
  if (menu.children && menu.children.length) {
    const itemOpenNames = openNames.concat([menu.path]);
    return menu.children.reduce((all, item) => {
      all.push({
        path: item.path,
        openNames: itemOpenNames,
      });
      const foundChildren = transMenu(item, itemOpenNames);
      return all.concat(foundChildren);
    }, []);
  }
  return [menu].map((item) => ({
    path: item.path,
    openNames,
  }));
}

export function getMenuopen(to, menuList) {
  const allMenus = [];
  menuList.forEach((menu) => {
    const menus = transMenu(menu, []);
    allMenus.push({
      path: menu.path,
      openNames: [],
    });
    menus.forEach((item) => allMenus.push(item));
  });
  const currentMenu = allMenus.find((item) => item.path === to.path);
  return currentMenu ? currentMenu.openNames : [];
}

/**
 * @param {Number} times 回调函数需要执行的次数
 * @param {Function} callback 回调函数
 */
export const doCustomTimes = (times, callback) => {
  let i = -1;
  // eslint-disable-next-line no-plusplus
  while (++i < times) {
    callback(i);
  }
};

/**
 * @description 本地存储和获取标签导航列表
 */
export const setTagNavListInLocalstorage = (list) => {
  localStorage.tagNavList = JSON.stringify(list);
};
/**
 * @returns {Array} 其中的每个元素只包含路由原信息中的name, path, meta三项
 */
export const getTagNavListFromLocalstorage = () => {
  const list = localStorage.tagNavList;
  return list ? JSON.parse(list) : [];
};

/**
 * @param {Array} routeMetched 当前路由metched
 * @returns {Array}
 */
export const getBreadCrumbList = (route, homeRoute) => {
  const homeItem = { ...homeRoute, icon: homeRoute.meta.icon };
  const routeMetched = route.matched;
  if (routeMetched.some((item) => item.name === homeRoute.name)) return [homeItem];
  let res = routeMetched
    .filter((item) => item.meta === undefined || !item.meta.hideInBread)
    .map((item) => {
      const meta = { ...item.meta };
      // if (meta.title && typeof meta.title === 'function') {
      //   meta.__titleIsFunction__ = true;
      //   meta.title = meta.title(route);
      // }
      const obj = {
        icon: (item.meta && item.meta.icon) || '',
        name: item.name,
        meta,
      };
      return obj;
    });
  res = res.filter((item) => !item.meta.hideInMenu);
  return [{ ...homeItem, to: homeRoute.path }, ...res];
};

/**
 * @param {Array} routers 路由列表数组
 * @description 用于找到路由列表中name为home的对象
 */
export const getHomeRoute = (routers, homeName = 'home') => {
  let i = -1;
  const len = routers.length;
  let homeRoute = {};
  // eslint-disable-next-line no-plusplus
  while (++i < len) {
    const item = routers[i];
    if (item.children && item.children.length) {
      const res = getHomeRoute(item.children, homeName);
      if (res.name) return res;
    } else if (item.name === homeName) homeRoute = item;
  }
  return homeRoute;
};

/**
 * @param {Array} list 标签列表
 * @param {String} name 当前关闭的标签的name
 */
export const getNextRoute = (list, route) => {
  let res = {};
  if (list.length === 2) {
    res = getHomeRoute(list);
  } else {
    const index = list.findIndex((item) => routeEqual(item, route));
    if (index === list.length - 1) res = list[list.length - 2];
    else res = list[index + 1];
  }
  return res;
};

/**
 * 判断打开的标签列表里是否已存在这个新添加的路由对象
 */
export const routeHasExist = (tagNavList, routeItem) => {
  const len = tagNavList.length;
  let res = false;
  doCustomTimes(len, (index) => {
    if (routeEqual(tagNavList[index], routeItem)) res = true;
  });
  return res;
};

export function duplicate(arr, key) {
  if (key) {
    const hash = {};
    const result = arr.reduce((item, next) => {
      // eslint-disable-next-line no-unused-expressions
      hash[next[key]] ? '' : (hash[next[key]] = true && item.push(next));
      return item;
    }, []);
    return result;
    // eslint-disable-next-line no-else-return
  } else {
    const x = new Set(arr);
    return [...x];
  }
}

/*
 * 处理列信息生成字典数据，需要检查本地数据，如果本地存在则不调用接口
 * @param {Array} columns
 * @return {Array}
 */
export async function handleTableDict(columns) {
  const dictData = JSON.parse(Storage.l.get('DictData') || '{}');
  for (const colItem of columns) {
    if (
      colItem.dictCode
      && colItem.editRender
      && ''.toLowerCase.call(colItem.editRender.name).includes('select')
    ) {
      if (_.has(dictData, colItem.dictCode)) {
        colItem.editRender.options = dictData[colItem.dictCode]; // 本地缓存赋值
      } else {
        // 如果都没有获取到，则调用接口
        // eslint-disable-next-line no-await-in-loop
        const resData = await request.post('/mdm/mdmdictdata/list', {
          dictTypeCode: colItem.dictCode,
        });
        if (resData.success) {
          colItem.editRender.options = resData.result.data || resData.result || [];
          // 缓存进本地
          dictData[colItem.dictCode] = colItem.editRender.options;
        }
      }
      // 如果存在options
      if (colItem.editRender.options && colItem.editRender.options.length) {
        colItem.editRender.optionProps = {
          value: 'dictCode',
          label: 'dictValue',
        };
      }
    }
    if (
      colItem.apiUrl
      && colItem.editRender
      && ''.toLowerCase.call(colItem.editRender.name).includes('select')
    ) {
      // eslint-disable-next-line no-await-in-loop
      const resData = await request.post(colItem.apiUrl, colItem.params || {});
      if (resData.success) {
        colItem.editRender.options = resData.result.data || resData.result || [];
      }
    }
  }
  // 循环结束后
  Storage.l.set('DictData', dictData, true);
  return columns;
}

/**
 *  处理路由是否相同
 * @author Boiao Ch
 * @param {routes} routelist 路由列表信息
 * @param {[string]} targetList 目标匹配值集合
 * @param {string} [field='name'] 匹配值属性字段名字
 * @param {string} [childrenField='children'] 子集合字段名字
 * @returns {routes} 返回过滤后的路由
 */
export const handleRouterSame = (
  routelist,
  targetList,
  field = 'name',
  childrenField = 'children',
) => {
  const temp = []; // 临时缓存数组
  for (const routeItem of routelist) {
    const toBeCover = targetList.includes(routeItem[field]); // 是否要被覆盖 判断其属性是否存在
    // 判断子列表是否存在
    if (!routeItem[childrenField] || !routeItem[childrenField].length) {
      // 子列表不存在则判断是否被覆盖了
      if (!toBeCover) {
        // 没有改则直接添加，如果覆盖了，则丢掉该项目
        temp.push(routeItem);
      }
    } else if (!toBeCover) {
      // 子列表存在的情况，判断是否是否被覆盖
      // 子列表没有被覆盖的时候，开始处理子列表 中的被覆盖情况
      const item = routeItem;
      // 递归调用处理子路由
      item[childrenField] = handleRouterSame(
        routeItem[childrenField],
        targetList,
        field,
        childrenField,
      );
      temp.push(routeItem);
    }
    // return routeItem;
  }
  return temp;
};

/**
 * 获取指定属性的集合，获取子级别
 * @author Boiao Ch
 * @param {routes} list 路由列表集合
 * @param {string} [propsField='name'] 提取字段名
 * @param {string} [childrenField='children'] 子列表集合属性字段名
 * @returns {[string]} 字符串集合
 */
export const getPropsCollection = (list, propsField = 'name', childrenField = 'children') => {
  let temp = [];
  if (list && list.length) {
    list.forEach((item) => {
      // 存在属性，则追加到缓存
      if (item[propsField]) {
        temp.push(item[propsField]);
      }
      // 如果存在子列表
      if (item[childrenField] && item[childrenField].length) {
        temp = temp.concat(getPropsCollection(item[childrenField], propsField, childrenField));
      }
    });
  }
  return temp;
};

/**
 * 时间戳转换'YYYY-MM-DD HH-mm-SS'
 * @param {string} time 时间戳
 */
export const getNowFormatDate = (time) => {
  const date = new Date(time);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let strDate = date.getDate();
  if (month >= 1 && month <= 9) {
    month = `0${month}`;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = `0${strDate}`;
  }
  let hh = date.getHours();
  hh = hh < 10 ? `0${hh}` : hh;
  let mm = date.getMinutes();
  mm = mm < 10 ? `0${mm}` : mm;
  let ss = date.getSeconds();
  ss = ss < 10 ? `0${ss}` : ss;

  const currentdate = `${year}-${month}-${strDate} ${hh}:${mm}:${ss}`;
  return currentdate;
};

export default null;

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
  const event = document.createEvent('HTMLEvents');
  event.initEvent('resize', true, true);
  event.eventType = 'message';
  window.dispatchEvent(event);
}

/**
 * 退出操作，删除本地用户数据
 *
 * @export
 */
export function logout() {
  localStorage.clear();
  sessionStorage.clear();
  _.cookie.remove('loginUserToken');
}

export function isVueOptions(options) {
  if (!options) {
    return false;
  }
  if (!_.isObject(options)) {
    return false;
  }

  return (
    typeof options.template === 'string'
    || typeof options.render === 'function'
    || typeof options.created === 'function'
  );
}

export function validateCode(str) {
  const reg = new RegExp(/^[a-zA-Z0-9]*$/);
  console.log(reg.test(str));
  return reg.test(str);
}

export function getConfig(parameterCode) {
  return request.get('/mdm/mdmParameterController/getParameterValue', {
    parameterCode,
  });
}

/**
 * 获取uuid
 * @param {Number} len 获取的uuid长度
 * @param {Number} radixs 基数2，10，16
 */
export function getUuid(len, radixs) {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  const uuid = [];
  const radix = radixs || chars.length;
  if (len) {
    for (let i = 0; i < len; i += 1) uuid[i] = chars[0 | (Math.random() * radix)]; // eslint-disable-line no-bitwise
  } else {
    let r;
    // requires these characters
    uuid[8] = '-';
    uuid[13] = '-';
    uuid[18] = '-';
    uuid[23] = '-';
    uuid[14] = '4';
    // Fill in random data.  At i==19 set the high bits of clock sequence as
    for (let i = 0; i < 36; i += 1) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16); // eslint-disable-line no-bitwise
        uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r]; // eslint-disable-line no-bitwise
      }
    }
  }
  return uuid.join('');
}
/**
 * @desc 两个数组去重
 * @param {Array} array1 数组1
 * @param {Array} array2 数组2
 * @param {String} key 去重字段
 * */
export function uniqueArr(array1, array2, key) {
  const arraydata = array1.concat(array2);
  const hash = {};
  const newArr = arraydata.reduce((item, next) => {
    if (!hash[next[key]]) {
      hash[next[key]] = true;
      item.push(next);
    }
    return item;
  }, []);
  return newArr;
}

/**
 * 判断一个节点是否在另一个节点内部
 * @param  node1 判断的节点
 * @param  node2 范围节点
 */
export function isFatcher(node1, node2) {
  while (node1.parentNode) {
    // eslint-disable-next-line no-param-reassign
    node1 = node1.parentNode;
    if (node1.parentNode === node2) return true;
  }
  return false;
}

/**
 * AES加密 256加密
 */
export const encodeKey256 = (word) => {
  const key = CryptoJS.enc.Utf8.parse('LiRPsOHAdiEUXGNAUHSgzfYuxsVAoHRH');
  const iv = CryptoJS.enc.Utf8.parse('UktEwPiokTfVTPuN');
  const srcs = CryptoJS.enc.Utf8.parse(word);
  const encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};

/**
 * AES加密 128加密
 */
export const encodeKey128 = (word) => {
  const key = CryptoJS.enc.Utf8.parse('R4xP2hFN2J2w678c');
  const srcs = CryptoJS.enc.Utf8.parse(word);
  const encrypted = CryptoJS.AES.encrypt(srcs, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};

/**
 * 下载文件
 * @params file Object
 */
export const download = (file, fileName) => {
  const content = file;
  // const blob = new Blob(content) // 构造一个blob对象来处理数据
  // 对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
  // IE10以上支持blob但是依然不支持download
  if ('download' in document.createElement('a')) {
    // 支持a标签download的浏览器
    const link = document.createElement('a'); // 创建a标签
    link.download = fileName; // a标签添加属性
    link.style.display = 'none';
    link.href = file;
    document.body.appendChild(link);
    link.click(); // 执行下载
    URL.revokeObjectURL(link.href); // 释放url
    document.body.removeChild(link); // 释放标签
  }
};
