import axios from 'axios';
// import qs from 'qs';
import Storage from './storage';

const defaultOptions = () => ({
  withCredentials: true,
  timeout: 300000,
});
const BaseUrl = '';
// if (process.env.NODE_ENV === 'production') {
//   BaseUrl = '';
// } else {
//   BaseUrl = '/api';
// }
// export const portUrl = process.env.VUE_APP_BASE_API !== '/' ? process.env.VUE_APP_BASE_API : ''; // 导出ip
export const portUrl = process.env.NODE_ENV === 'production' ? '' : process.env.VUE_APP_BASE_API; // 导出ip
const { protocol, hostname, host } = window.location;
let websocketUrlName = process.env.VUE_APP_WEB_SOCKET_ADDREESS;
if (protocol === 'http:') {
  websocketUrlName = `ws://${host}`;
} else if (protocol === 'https:') {
  websocketUrlName = `wss://${host}`;
}
export const websocketUrl = process.env.NODE_ENV === 'production' ? websocketUrlName : process.env.VUE_APP_WEB_SOCKET_ADDREESS;

axios.interceptors.request.use((config) => {
  const configCopy = { ...config };
  if (window.App && window.App.$store) {
    window.App.$store.commit('SetButtonIsLoading', true);
  }

  // if (token) {
  //   configCopy.headers = {
  //     loginUserToken: token,
  //     menuCode: window.App && window.App.$store && window.App.$store.state.menus.selectMenu,
  //     // functionCode: new Date().getTime(),
  //   };
  // }

  configCopy.baseURL = BaseUrl;
  return configCopy;
});

axios.interceptors.response.use(
  (response) => {
    if (window.App && window.App.$store) {
      window.App.$store.commit('SetButtonIsLoading', false);
    }
    if (response.status === 200) {
      if (response.data.code === 401) {
        Storage.l.remove('TokenKey');
        window.App.$router.replace('/login');
      } else {
        if (Object.keys(response.data).includes('success')) {
          if (!response.data.success) {
            window.App.$message.error(response.data.message || '请求失败');
            return Promise.reject(response.data);
          }
          return response.data;
        }
        return Promise.reject(response.data);
      }
    }
    return null;
  },
  (error) => {
    if (window.App && window.App.$store) {
      window.App.$store.commit('SetButtonIsLoading', false);
    }
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          window.App.$message.error('请求出错');
          break;
        case 403:
          window.App.$message.error('没有权限');
          break;
        case 404:
          window.App.$message.error('资源不存在');
          break;
        case 500:
          window.App.$message.error('服务器错误');
          break;
        default:
          window.App.$message.error('服务器连接错误');
          break;
      }
    } else {
      window.App.$message.error('服务器连接错误');
    }
    return Promise.reject(error.response);
  },
);
const hasParentCode = ['/mdm/mdmtableconfig/query', '/mdm/mdmtableconfig/form/query'];

let request;
export default request = {
  // Base_Url,
  get: (url, data, options = {}) => new Promise((resolve, reject) => {
    const token = localStorage.getItem('TokenKey');
    let pData = data;
    const functionCode = (options.headers && options.headers.functionCode) || '';
    if (hasParentCode.includes(url) && !data.parentCode) {
      pData = {
        parentCode: window.App && window.App.$store && window.App.$store.state.menus.selectMenu,
        ...pData,
      };
    }

    axios(`${url}?getApiTime=${new Date().getTime()}`, {
      method: 'get',
      headers: {
        functionCode,
        'Content-Type': 'application/json; charset=utf-8',
        loginUserToken: token || options.loginUserToken,
        menuCode: window.App && window.App.$store && window.App.$store.state.menus.selectMenu,
      },
      ...defaultOptions(),
      params: pData || {},
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  }),
  post: (url, data, options = {}) => new Promise((resolve, reject) => {
    const token = localStorage.getItem('TokenKey');
    let pData = data;
    const functionCode = (options.headers && options.headers.functionCode) || '';
    const ContentType = options?.headers?.ContentType;
    if (hasParentCode.includes(url)) {
      pData = {
        parentCode: window.App && window.App.$store && window.App.$store.state.menus.selectMenu,
        ...pData,
      };
    }
    axios(url, {
      method: 'post',
      data: pData || {},
      params: ContentType ? pData : '',
      ...options,
      headers: {
        functionCode,
        'Content-Type': ContentType || 'application/json; charset=utf-8',
        loginUserToken: token || options.loginUserToken,
        menuCode: window.App && window.App.$store && window.App.$store.state.menus.selectMenu,
      },
      ...defaultOptions(),
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  }),
  postform: (url, data, options = {}) => new Promise((resolve, reject) => {
    const token = localStorage.getItem('TokenKey');
    const functionCode = (options.headers && options.headers.functionCode) || '';
    axios(url, {
      method: 'post',
      data,
      headers: {
        functionCode,
        'Content-Type': 'application/json; charset=utf-8',
        loginUserToken: token,
        menuCode: window.App && window.App.$store && window.App.$store.state.menus.selectMenu,
      },
      ...defaultOptions(),
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  }),
  formpost: (url, data, options = {}) => new Promise((resolve, reject) => {
    const token = localStorage.getItem('TokenKey');
    const functionCode = (options.headers && options.headers.functionCode) || '';
    axios(url, {
      method: 'post',
      data,
      headers: {
        functionCode,
        'Content-Type': 'multipart/form-data; charset=utf-8',
        loginUserToken: token,
        menuCode: window.App && window.App.$store && window.App.$store.state.menus.selectMenu,
      },
      ...defaultOptions(),
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  }),
  patch: (url, data, options = {}) => new Promise((resolve, reject) => {
    const token = localStorage.getItem('TokenKey');
    const functionCode = (options.headers && options.headers.functionCode) || '';
    axios(url, {
      method: 'patch',
      data,
      headers: {
        functionCode,
        'Content-Type': 'application/json; charset=utf-8',
        loginUserToken: token,
        menuCode: window.App && window.App.$store && window.App.$store.state.menus.selectMenu,
      },
      ...defaultOptions(),
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  }),
  delete: (url, data, options = {}) => new Promise((resolve, reject) => {
    const token = localStorage.getItem('TokenKey');
    let pData = data;
    const functionCode = (options.headers && options.headers.functionCode) || '';
    if (hasParentCode.includes(url) && !data.parentCode) {
      pData = {
        parentCode: window.App && window.App.$store && window.App.$store.state.menus.selectMenu,
        ...pData,
      };
    }
    axios(`${url}?getApiTime=${new Date().getTime()}`, {
      method: 'delete',
      headers: {
        functionCode,
        'Content-Type': 'application/json; charset=utf-8',
        loginUserToken: token || options.loginUserToken,
        menuCode: window.App && window.App.$store && window.App.$store.state.menus.selectMenu,
      },
      ...defaultOptions(),
      params: pData || {},
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  }),
};
const httpPlugin = {};
httpPlugin.install = function (Vue) {
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$http = request;
};
export { httpPlugin };
/**
 * @desc async/await 异常处理
 * @todo 为避免大面积try/catch，增加此方法
 * @example const [err,result]=await promiseCatch(request.post('/www.baidu.com'))
*/
export const promiseCatch = (promise) => promise.then((res) => [undefined, res]).catch((err) => [err, undefined]);
