<template>
  <div>
    <el-upload
      class="upload-demo"
      v-if="!disabled"
      action="/upload/uploadController/uploadOssList"
      :headers="{
        loginUserToken: Storage.l.get('TokenKey'),
      }"
      :on-progress="changeProgress"
      :show-file-list="false"
      :before-upload="beforeUpload"
      :on-preview="handlePreview"
      :before-remove="beforeRemove"
      :on-success="handleSuccess"
      :limit="limit"
      :accept="accept || ''"
      :on-exceed="handleExceed"
      :file-list="fileList"
    >
      <el-button size="small" type="primary">点击上传</el-button>
      <div slot="tip" class="el-upload__tip">
        {{ fileMsg ? fileMsg : "只能上传jpg/png文件，且不超过100M" }}
      </div>
      <div slot="tip">
        <div v-for="(item, index) in fileList" class="file-list" :key="index">
          <i class="el-icon-tickets"></i>
          <div class="file-name">{{ item.name }}</div>
          <a
            :href="`/upload/downloadController/download?objectName=${item.objectName}`"
            :download="item.name"
            ><i class="el-icon-download"></i
          ></a>
          <i class="el-icon-close" @click="handleRemove(item)"></i>
        </div>
        <el-progress v-if="onUpload" :percentage="progress"></el-progress>
      </div>
    </el-upload>
    <div v-else class="file-list-detail">
      <div v-for="(item, index) in fileList" class="file-list" :key="index">
        <i class="el-icon-tickets"></i>
        <div class="file-name">{{ item.name }}</div>
        <a
          :href="`/upload/downloadController/download?objectName=${item.objectName}`"
          :download="item.name"
          ><i class="el-icon-download"></i
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
import Storage from '../../../utils/storage';
import request from '../../../utils/request';
import { download } from '../../../utils/index';

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    limit: {
      type: Number,
      default: 999,
    },
    accept: String,
    disabled: Boolean,
    fileMsg: String,
    limitSize: {
      type: String,
      default: '',
    },
    limitAccept: String,
  },
  data() {
    return {
      Storage,
      isUpload: false,
      onUpload: false,
      progress: 0,
      fileSize: '1000',
      fileAccept: '',
    };
  },
  computed: {
    fileList() {
      const fileList = [];
      if (this.value) {
        this.value.forEach((v) => {
          fileList.push({
            name: v.fileName,
            url: v.url ? v.url : v.filePath,
            urlPath: v.urlPath,
            urlPathPrefix: v.urlPathPrefix,
            objectName:
              v.objectName
              || ((v.url ? v.url : v.filePath || v.fileUrl) || '').split('/').splice(-3).join('/'),
          });
        });
      }
      return fileList;
    },
  },
  created() {
    this.getSizeAndAccept();
  },
  methods: {
    /**
     * 获取配置的文件大小和格式
     */
    getSizeAndAccept() {
      const params = ['upload_limit'];
      request.post('/mdm/mdmdictdata/batchDictSelect', params).then((res) => {
        if (res.success) {
          const uploadLimit = res.result.upload_limit || [];
          const limit = uploadLimit.find((a) => a.dictCode === this.limitSize);
          const accept = uploadLimit.find((a) => a.dictCode === this.limitAccept);
          if (accept && accept.dictValue) {
            accept.dictValue = `.${accept.dictValue.split(',').join(',.')}`;
          }
          this.fileSize = limit ? limit.dictValue : '1000';
          this.fileAccept = accept ? accept.dictValue : '*';
          // console.log(this.fileSize);
          // console.log(this.fileAccept);
        }
      });
    },
    beforeUpload(files) {
      this.progress = 0;
      this.onUpload = true;
      const { name, size } = files;
      let fileType = name.split('.')[name.split('.').length - 1];
      fileType = fileType.toLowerCase();
      const isLt10M = size / 1024 / 1024 <= Number(this.fileSize);
      if (this.limitAccept) {
        if (
          this.fileAccept
          && this.fileAccept.indexOf(fileType) === -1
          && !this.fileAccept.includes('*')
        ) {
          this.isUpload = true;
          this.onUpload = false;
          this.$message.warning(`当前限制选择 ${this.fileAccept} 类型件，本次选择了错误文件`);
          return false;
        }
      }
      if (this.accept) {
        if (this.accept && this.accept.indexOf(fileType) === -1 && !this.accept.includes('*')) {
          this.isUpload = true;
          this.onUpload = false;
          this.$message.warning(`当前限制选择 ${this.accept} 类型件，本次选择了错误文件`);
          return false;
        }
      }

      if (!isLt10M) {
        this.isUpload = true;
        this.onUpload = false;
        this.$message.error(`上传表格大小不超过${this.fileSize}MB`);
        return false;
      }
      return true;
    },
    handleRemove(file) {
      this.$confirm(`确定移除 ${file.name}？`).then(
        (res) => {
          if (res === 'confirm') {
            this.value.forEach((v, k) => {
              if (v.url) {
                if (v.url === file.url) {
                  this.value.splice(k, 1);
                }
              } else if (v.realPath + v.urlPath === file.url) {
                this.value.splice(k, 1);
              }
            });
          }
        },
        () => {},
      );
    },
    handlePreview(file) {
      console.log(file);
    },
    handleSuccess(res) {
      this.isUpload = false;
      if (res.code === 200 && res.success) {
        res.result.forEach((v) => {
          this.value.push({
            fileName: v.fileName,
            url: v.url,
            urlPath: v.urlPath,
            urlPathPrefix: v.urlPathPrefix,
            objectName: v.objectName,
          });
          this.$emit('onGetFileList', this.value);
          this.$emit('change', this.value);
        });
      }
    },
    handleExceed(files, fileList) {
      console.log(files, fileList);
      // this.$message.warning(
      //   `当前限制选择 3 个文件，本次选择了 ${
      //     files.length
      //   } 个文件，共选择了 ${files.length + fileList.length} 个文件`,
      // );
    },
    beforeRemove(file) {
      if (!this.isUpload) {
        return this.$confirm(`确定移除 ${file.name}？`);
      }
      this.isUpload = false;

      return true;
    },
    changeProgress(event, file) {
      const progress = setInterval(() => {
        this.progress = file.percentage;
        if (file.percentage === 100) {
          clearInterval(progress);
          setTimeout(() => {
            this.onUpload = false;
            this.progress = 0;
          }, 1000);
        }
      }, 30);
    },
    // 文件下载
    downloadfile(files) {
      download(`${files.url}`, files.name);
    },
  },
};
</script>

<style lang="less" scoped>
.file-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 18px;
  .file-name {
    flex: 1;
    padding: 0 10px;
    width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
  > i {
    padding: 0 5px;
  }
}
.file-list-detail {
  padding: 10px 0;
}
</style>
